.site-preview-intro {
  background: lighten($gray-200, 3%);
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.site-preview-choices {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
