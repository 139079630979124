.card {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;

  a:not(.btn) {
    color: inherit;
    text-decoration: none;
  }

  p a {
    text-decoration: underline;
  }
}

.card-outline {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.card-header time {
  margin-bottom: .5rem;
  display: inline-block;
}

.card-img {
  transition: all .2s cubic-bezier(.42,0,.58,1);

  &:hover {
    box-shadow: 0 18px 32px -18px $black;
    transform: translateY(-6px);
  }
}

.card-text a {
  text-decoration: underline;
}

.card-meta a:hover {

  &,
  time {
    text-decoration: underline;
  }
}

.card-img-border {
  border: 1px solid rgba($black, .2);
}
