.intro {
  &-block {
    height: 500px;
    background-position: center center;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 10px;
    color: $white;
    text-align: center;
    padding: 20px;
    position: relative;
    overflow: hidden;
    display: block;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: $white;
      text-decoration: none;
    }

    &:before {
      content:"";
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      background: inherit;
      transition: inherit;
      transition: all .25s ease-in-out;
    }
    
    &:hover:before{
      transform: scale(1.05);
    }

    &-inner {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      opacity: 0;
      transition: all .25s ease-in-out;
    }

    &:hover &-inner,
    &.active &-inner {
      opacity: 1;
    }
  }

  margin-bottom: 2rem;
  padding-bottom: 0rem;
}
