// Fonts
@import url('https://fonts.googleapis.com/css?family=PT+Sans+Caption:400,700|PT+Serif:700');

// Theme variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Theme components
@import "forms";
@import "buttons";
@import "nav";
@import "navbar";
@import "card";
@import "main";
@import "intro";
@import "footer";
@import "instagram";
@import "sidebar";
@import "newsletter";
@import "vimeo";
@import "preview";